import React from 'react';
import { Breadcrumb, Col, Divider, Image, Row, Typography } from 'antd';
import './InstructorSection.less';
import haroldkolo from '../../assets/images/haroldkolo.png';
import haroldkwadrat from '../../assets/images/haroldkwadrat.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ROUTES } from '../../constants/routes';

const InstructorSection = () => {
  const { Title, Text, Paragraph } = Typography;

  return (
    <section className="section-content-container">
      <section className="instructor-section">
        <Breadcrumb separator={<ArrowRightOutlined />}>
          <Breadcrumb.Item href={ROUTES.HOME}>Strona główna</Breadcrumb.Item>
          <Breadcrumb.Item href={ROUTES.ABOUT}>O nas</Breadcrumb.Item>
        </Breadcrumb>
        <Divider></Divider>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 16 }}>
            <Title level={3} className="instructor-section-title">
              Poznaj Społeczeństwo Wiedzy
              <Divider />
            </Title>
            <div className="instructor-section-image-wrapper">
              <Image
                preview={false}
                className="instruction-section-image"
                src={haroldkolo}
              ></Image>
            </div>
            <Title className="instructor-section-heading" level={3}>
              Franciszek Bednarek
            </Title>
            <Text className="instructor-section-button">Prowadzący</Text>
            <Paragraph className="instruction-sction-paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </Paragraph>
          </Col>
          <Col span={6} offset={2}>
            <div className="instructor-section-image-wrapper2">
              <Image
                preview={false}
                className="instruction-section-image"
                src={haroldkwadrat}
              ></Image>
            </div>
          </Col>
        </Row>
      </section>
    </section>
  );
};
export default InstructorSection;

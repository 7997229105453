import React, { FC } from 'react';
import { Card, Image } from 'antd';
import './ReviewerCard.less';

const { Meta } = Card;

type ReviewerCardProps = {
  image: string;
  name: string;
  description: string;
};

const ReviewerCard: FC<ReviewerCardProps> = ({ name, image, description }) => {
  return (
    <div className="reviewer-card-container">
      <Card
        className="reviewer-card"
        cover={
          <div className="image-container-card">
            <Image
              className="image-reviewer-card"
              src={image}
              preview={false}
            />
          </div>
        }
      >
        <Meta
          className="reviewer-card-meta"
          title={name}
          description={description}
        />
      </Card>
      <div className="reviewer-card-background"></div>
    </div>
  );
};

export default ReviewerCard;

import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { MISSIONS } from '../../assets/mocks/missions';
import './MissionSection.less';

const MissionSection: FC = () => {
  const { Title, Paragraph } = Typography;

  return (
    <section className="section-content-container">
      <Title level={3} className="mission-section-heading">
        Nasza misja
      </Title>

      <Row className="mission-section-row">
        {MISSIONS.map((mission, index) => {
          return (
            <Col key={index} sm={{ span: 24 }} md={{ span: 7 }}>
              <Title level={4} className="mission-section-motto">
                {mission.title}
              </Title>
              <Paragraph className="mission-section-text">
                {mission.text}
              </Paragraph>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default MissionSection;

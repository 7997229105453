import React from 'react';
import InstructorSection from '../components/InstructorSection/InstructorSection';
import MissionSection from '../components/MissionSection/MissionSection';
import TrySection from '../components/TrySection/TrySection';
import { Layout } from '../components/Layout/Layout';
import ReviewerSection from '../components/ReviewerSection/ReviewerSection';
import './about.less';

export default function About({}) {
  return (
    <Layout>
      <InstructorSection />
      <div className="about-mission-section">
        <MissionSection />
      </div>
      <ReviewerSection />
      <div className="about-try-section">
        <TrySection />
      </div>
    </Layout>
  );
}

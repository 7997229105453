import React, { FC, useState } from 'react';
import { Carousel, Col, Row, Typography } from 'antd';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import { data } from '../../assets/mocks/reviewerSection';
import ReviewerCard from '../ReviewerCard/ReviewerCard';
import './ReviewerSection.less';

const ReviewerSection = ({}) => {
  const { Title, Text, Paragraph } = Typography;
  const { isMobile } = useMediaQuery();
  const [currentCard, setCurrentCard] = useState(0);

  const renderCards = () => {
    const Cards = data.map((card, id) => {
      const cardElement = (
        <ReviewerCard
          key={card.id}
          image={card.image}
          name={card.name}
          description={card.description}
        />
      );
      return !isMobile ? <Col span={7}>{cardElement}</Col> : cardElement;
    });

    const carouselProps = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
        {!isMobile ? (
          <Row className="reviewer-section-row">{Cards}</Row>
        ) : (
          <Carousel
            {...carouselProps}
            className="reviewer-carousel-dots"
            afterChange={currentID => setCurrentCard(currentID)}
          >
            {Cards}
          </Carousel>
        )}
      </>
    );
  };

  return (
    <section className="section-content-container">
      <div className="reviewer-section">
        <Title level={3} className="reviewer-section-heading">
          Recenzenci Platformy
        </Title>
        <Col sm={{ span: 24 }} lg={{ span: 15, offset: 0 }}>
          <Paragraph className="reviewer-section-paragraph">
            Poznaj osoby, które weryfikują nasze treści i kontrolują, aby
            przekazywana przez nas wiedza pozostawała na najwyższym poziomie.
          </Paragraph>
        </Col>
        {renderCards()}
      </div>
    </section>
  );
};

export default ReviewerSection;

import courseImage from '../images/ellipse.png';

export const data = [
  {
    id: 1,
    image: courseImage,
    name: 'Imię Nazwisko',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporv',
  },
  {
    id: 2,
    image: courseImage,
    name: 'Imię Nazwisko',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporv',
  },
  {
    id: 3,
    image: courseImage,
    name: 'Imię Nazwisko',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporv',
  },
];
